<style>
#app .v-dialog .v-card__actions {
    padding: 0.5rem 2rem 1rem;
}
</style>
<template>
<!--eslint-disable-->
    <div v-show="detail">
        <v-row>
            <v-col class="text-left" xs="12" sm="12" md="5" lg="5" xl="5" >
                <h4 class="bold">{{$t('solicitudes.DetallesolicitudID')}}: {{ preot_id }}</h4>
                <div class="d-flex bg-ef align-items-center">
                <a class="mr-2">
                    <svg style="width:17px;height:17px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />
                    </svg>
                </a>
                /
                <a class="mx-2 fs11 text-decoration-none"
                type="button" @click="backListRequest()">
                    {{$t('solicitudes.coo')}}</a>
                /
                <a class="mx-2 fs11 gray_color text-decoration-none" href="#">{{$t('solicitudes.Detallesolicitud')}}</a>
                </div>
            </v-col>
            <v-col xs="12" sm="12" md="7" lg="7" xl="7" >
                <div class="d-flex justify-content-end">
                    <v-btn class="mr-4 ma-2 c-btn c-btn--primary" color="indigo darken-3" @click="showModalEditarDatosGeneralesPreOT()">
                        {{$t('solicitudes.editsoli')}}
                    </v-btn>

                    <v-btn color="black" class="ma-2 white--text" @click="showModalEliminarPreOT()">
                        {{$t('solicitudes.deletesoli')}}
                    </v-btn>
                </div>
            </v-col>
            <v-col xs="12" sm="12" md="5" lg="5" xl="5" >
                <v-simple-table>
                <template v-slot:default>
                    <thead>
                    </thead>
                    <tbody>
                        <tr v-for="(item, key) in ObjSolicitudDatosGenerales">
                            <td class="text-left w-50">{{ item.name }}</td>
                            <td class="text-left w-50 gray_color">{{ item.text }}</td>
                        </tr>
                    </tbody>
                </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <div v-for="(item, key) in ListMuestras">
                    <div v-if="item.length > 0">
                        <h4 class="text-left bold">{{getlang(key)}}: {{item.length}}</h4>
                        <v-data-table
                            :headers="headerdetalle"
                            :items="item"
                            :items-per-page="5"
                            class="elevation-1"
                            :footer-props="{itemsPerPageText: textofooter}"
                        >
                            <template v-slot:item.opciones="{ item }">
                                <div class="d-flex align-items-center justify-content-space-around">
                                    <a @click="showModalEnsayos( item.matriz_id, item.id )" class="matraz">
                                        <svg  width="18" height="18">
                                            <use xlink:href='/img/icons/detalle-icons.svg#matraz'></use>
                                        </svg>
                                    </a>
                                    <a @click="showModalEdit( item.matriz_id, item.id )" class="edit">
                                    <svg width="18" height="18">
                                        <use xlink:href='/img/icons/detalle-icons.svg#edit'></use>
                                    </svg>
                                    </a>
                                    <!-- <a @click="trash()" class="trash"> -->
                                    <a  @click="trash( item.matriz_id, item.id )" class="trash">
                                    <svg width="18" height="18">
                                        <use xlink:href='/img/icons/detalle-icons.svg#trash'></use>
                                    </svg>
                                    </a>
                                </div>
                            </template>
                        </v-data-table>
                    </div>
                </div>
                
            </v-col>
        </v-row>

        <!-- modal eliminar muestra -->
        <v-dialog v-model="dialogdelete" max-width="290">
            <v-card>
                <v-card-title class="text-h5 flex-direction-column" style="padding-bottom: 0px !important;">
                    <svg  width="60.575" height="60.576">
                    <use xlink:href='/img/icons/detalle-icons.svg#warning'></use>
                    </svg>
                    <span class="txt-title-alert">{{$t('solicitudes.advertencia1')}}</span>
                </v-card-title>
                <v-card-text>
                    {{$t('solicitudes.txt_delete')}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    class="mr-4 ma-2 c-btn c-btn--primary"
                    color="indigo darken-3"
                    @click="deleteMuestraPreOT()"
                    >
                    {{$t('analisis.aceptar')}}
                    </v-btn>
                    <v-btn
                    color="black"
                    class="ma-2 white--text"
                    @click="dialogdelete = false"
                    >
                    {{ $t('ensayos.cancelar') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- modal eliminar solicitud -->
        <v-dialog v-model="dialogEliminarPreOT" max-width="290">
            <v-card>
                <v-card-title class="text-h5 flex-direction-column" style="padding-bottom: 0px !important;">
                    <svg  width="60.575" height="60.576">
                        <use xlink:href='/img/icons/detalle-icons.svg#warning'></use>
                    </svg>
                    <span class="txt-title-alert">{{$t('solicitudes.advertencia1')}}</span>
                </v-card-title>
                <v-card-text>
                    {{$t('solicitudes.txt_delete')}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mr-4 ma-2 c-btn c-btn--primary" color="indigo darken-3" @click="btnEliminarPreOT()">
                        {{$t('analisis.aceptar')}}
                    </v-btn>
                    

                    <v-btn color="black" class="ma-2 white--text" @click="dialogEliminarPreOT = false">
                        {{ $t('ensayos.cancelar') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- modal editar detalle solicitud -->
        <modaleditarsoli ref="modaleditarsoli"></modaleditarsoli>
        <!-- modal modalensayo -->
        <modalensayos ref="modalensayo"></modalensayos>
        <!-- modal modalmatriz -->
        <moddaleditmatriz ref="modalmatriz"></moddaleditmatriz>
    </div>
</template>

<script>
/*eslint-disable*/
import i18n from '../../plugins/il8n';
import Service from '../../services/apis';
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';

export default {
    // props:['formatDate'],
    components: {
        modaleditarsoli: () => import('./modaleditardellatesolicitud.vue'),

        modalensayos: () => import('./modalensayo.vue'),
        moddaleditmatriz: () => import('./modaledit.vue'),
    },
    computed: {
        ...mapState('solicitudesStore',['HiddenSolicitudDetailsStore','RemoveListRequestItemStore','FormatDateStore']),
        textofooter(){
        const textofooter = i18n.t('ensayos.Rowsperpage');
        return textofooter;
        },
        headerdetalle() {
            return [{
                text: 'id',
                align: 'start',
                sortable: false,
                value: 'id',
            },{
                text: i18n.t('solicitudes.desc'),
                align: 'start',
                sortable: false,
                value: 'desc',
            },{
                text: i18n.t('solicitudes.codigo_barras'),
                align: 'start',
                sortable: false,
                value: 'codigo_barras',
            },{
                text: i18n.t('solicitudes.f_elab'),
                align: 'start',
                sortable: false,
                value: 'f_elaboracion',
            },{
                text: i18n.t('solicitudes.f_ven'),
                align: 'start',
                sortable: false,
                value: 'f_vencimiento',
            },{
                text: i18n.t('solicitudes.opciones'),
                align: 'start',
                sortable: false,
                value: 'opciones',
            }];
        },
    },
    data: () => ({
        ObjSolicitudDatosGenerales : {
        },
        ListMuestras : {
            Alimentos     : [], //12
            Superficies   : [], // 9
            Manipuladores : [], // 8
            Agua          : [], // 7
            Ambientes     : [], // 6
        },
        preot_id : null,
        matriz_id: null,
        muestra_preot_id: null,
        dialogEliminarPreOT: false,
        detail: false,
        dialogdelete: false,
        dialogeditar: false,

    }),
    created(){
        // this.removeSampleRequest();
    },
    methods: {
        getlang(txt){
            let text_tranform = txt.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "").replace(/,/g, "");
            // console.log('txt', txt);
            // console.log('text_tranform', text_tranform);
            let txt_traduccion = i18n.t('solicitudes.'+[`${text_tranform}`]);
            // console.log("TRADUCCION",nrt);
            if(txt_traduccion){
                return txt_traduccion;
            }else{
                return txt;
            }
        },
        ...mapMutations('solicitudesStore',['fnSetEnsayosDataStore']),
        backListRequest(){
            this.HiddenSolicitudDetailsStore();
            this.detail = false;

            // RESET CAMPOS:
            this.ObjSolicitudDatosGenerales = {
            }
            this.ListMuestras = {
                Alimentos     : [], //12
                Superficies   : [], // 9
                Manipuladores : [], // 8
                Agua          : [], // 7
                Ambientes     : [], // 6
            }
            this.preot_id = null
            this.muestra_preot_id = null
            this.dialogEliminarPreOT = false
            this.detail = false
            this.dialogdelete = false
            this.dialogeditar = false
        },
        init( preot_id ) {
            this.preot_id = preot_id;
            // console.log({'preot_id':preot_id});

            this.detail = true;
            this.getDatosGeneralesSolicitud(preot_id);
            this.getMuestrasSolicitud(preot_id);

            this.getEnsayosMuestras();
        },
        // ------------------------------------------ EDITAR DATOS GENERALES - PREOT:
        showModalEditarDatosGeneralesPreOT(){
            // init
            this.$refs.modaleditarsoli.showmodal();
        },
        // ------------------------------------------ ELIMINAR - PREOT:
        showModalEliminarPreOT() {
            this.dialogEliminarPreOT = !this.dialogEliminarPreOT;
        },
        btnEliminarPreOT(){
            this.backListRequest();
            this.dialogEliminarPreOT = false;
            this.RemoveListRequestItemStore(this.preot_id);
            this.deleteSolicitud();
        },
        async deleteSolicitud(){
            const data = {
                preot_id : this.preot_id,
            }
            const data_set = await Service.apiToken('POST', 'deleteSolicitud', this.$ls.storage.token, data);
            // console.log({'data_set':data_set});

            if( data_set.exito ){
                this.detail = false;
            }
        },
        // ------------------------------------------ DETALLE - PREOT:
        async getDatosGeneralesSolicitud(preot_id){
            const data = {
                preot_id : preot_id
            }
            const data_set = await Service.apiToken('POST', 'getDatosGeneralesSolicitud', this.$ls.storage.token, data);
            // console.log({'data_set':data_set});
            this.ObjSolicitudDatosGenerales = {
                cliente : {
                    name: i18n.t('solicitudes.cliente'),
                    text: data_set.response.local.nombre_local,
                },
                planta : {
                    name: i18n.t('solicitudes.planta'),
                    text: data_set.response.local.cliente.nombre,
                },
                direccion : {
                    name: i18n.t('solicitudes.dir'),
                    text: data_set.response.local.cliente.direccion,
                },
                encargado : {
                    name: i18n.t('solicitudes.encargado'),
                    text: data_set.response.local.encargado,
                },
                fecha_presunta : {
                    name: i18n.t('solicitudes.fecha_presunta'),
                    text: this.FormatDateStore(data_set.response.fecha_presunta),
                },
            };

            return data_set;
        },
        async getMuestrasSolicitud(preot_id){
            const data = {
                preot_id : preot_id
            }
            const data_set = await Service.apiToken('POST', 'getMuestrasSolicitud', this.$ls.storage.token, data);
            // console.log({'data_set':data_set});
            data_set.response.forEach(item => {
                let matriz = '';
                switch( item.id_matriz ){
                    case 12: matriz = 'Alimentos';      break;
                    case  9: matriz = 'Superficies';    break;
                    case  8: matriz = 'Manipuladores';  break;
                    case  7: matriz = 'Agua';           break;
                    case  6: matriz = 'Ambientes';      break;
                    // default: break;
                }
                this.ListMuestras[matriz].push({
                    id              : item.id,
                    codigo_barras   : item.cod_barras,
                    desc            : item.descripcion,
                    // prima           : item.id,
                    f_elaboracion   : item.fec_elab,
                    f_vencimiento   : item.fec_ven,
                    // f_vencimiento   : this.formatDate(item.fec_ven),
                    // f_vencimiento   : this.$emit('formatDate', [item.fec_ven]),
                    // something() { this.$emit('event', [x: y]);
                    // parametros      : item.id,
                    matriz_id       : item.id_matriz,
                });
            });

            // console.log({'this.ListMuestras':this.ListMuestras});
            // console.log({'this.ListMuestras.Alimentos':this.ListMuestras.Alimentos});
            return data_set;
        },
        async getEnsayosMuestras(){
            const data = {
                preot_id : this.preot_id,
                // muestra_preot_id : muestra_preot_id
            }
            const data_set = await Service.apiToken('POST', 'getEnsayosMuestras', this.$ls.storage.token, data);
            // console.log({'data_set':data_set});

            const ensayosXmatriz = _.groupBy(data_set.response, 'matriz_id');
            this.fnSetEnsayosDataStore(ensayosXmatriz);
            /*data_set.map(function(item, key){
                Object.entries(ensayosXmatriz).forEach(([key2, item2]) => {
                // ensayosXmatriz.map(function(item2, key2){
                    if( item.matriz_id == key2 ){
                        ensayosXmatriz[key2] = item;
                    }
                });
            });*/
            console.log({'ensayosXmatriz':ensayosXmatriz});
        },
        removeSampleRequest(){
            let matriz = '';

            switch( this.matriz_id ){
                case 12: matriz = 'Alimentos';      break;
                case  9: matriz = 'Superficies';    break;
                case  8: matriz = 'Manipuladores';  break;
                case  7: matriz = 'Agua';           break;
                case  6: matriz = 'Ambientes';      break;
                // default: break;
            }

            this.ListMuestras[matriz] = this.ListMuestras[matriz].filter(item => item.id != this.muestra_preot_id);
            // console.log('ListMuestras' , ListMuestras);
        },
        async editDatosMuestra(){
        },
        async getDatosMuestra(){
        },
        async editDatosMuestra(){
        },
        async deleteMuestraPreOT(){
            const data = {
                preot_id : this.preot_id,
                muestra_preot_id : this.muestra_preot_id
            }

            const data_set = await Service.apiToken('POST', 'deleteMuestraPreOT', this.$ls.storage.token, data);
            // console.log({'data_set2':data_set});

            this.dialogdelete = false;
            this.removeSampleRequest();

            // location. reload()
            // this.$router.go(0)
        },
        // -------------------------
        showModalEnsayos(matriz_id, muestra_preot_id){
            this.muestra_preot_id = muestra_preot_id

            console.log('muestra_preot_id');
            // init
            this.$refs.modalensayo.init(matriz_id, this.muestra_preot_id);
            // console.log('click');
        },
        showModalEdit(matriz_id, muestra_preot_id){
            // init
            this.$refs.modalmatriz.openmodal();
        },
        trash(matriz_id, muestra_preot_id){
            this.matriz_id = matriz_id;
            this.muestra_preot_id = muestra_preot_id;
            this.dialogdelete = !this.dialogdelete;
        },
    },
}
</script>